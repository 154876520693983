import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login";
import Layout from "./container/Layout";
import Dashboard from "./pages/dashboard";
import Scenario from "./pages/scenarios";
import Cookies from "js-cookie";
import CreateScenario from "./pages/scenarios/CreateScenario";
import Games from "./pages/games";
import SelectScenario from "./pages/games/SelectScenario";
import CreateGame from "./pages/games/CreateGame";
import Users from "./pages/users";
import GameDetail from "./pages/games/GameDetail";
import CreateUser from "./pages/users/CreateUser";
import Banners from "./pages/banners";
import CreateBanner from "./pages/banners/CreateBanner";
import Characters from "./pages/characters";
import Avatar from "./pages/characters/Avatar";
import RegisterCharacter from "./pages/characters/RegisterCharacter";
import SideKickUser from "./sidekick/pages/sidekick-users/SideKickUser";
import ChangePassword from "./pages/users/ChangePassword";
import Statistics from "./sidekick/pages/statistics/Statistics";
const ProtectedRoute = ({ component: Component, ...props }) => {
  const auth = Cookies.get("token");
  return auth ? <Component {...props} /> : <Navigate to={"/"} replace />;
};

const AuthWrapper = ({ element: Component }) => {
  const auth = Cookies.get("token");
  return !auth ? <Component /> : <Navigate to={"/panel/dashboard"} replace />;
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AuthWrapper element={Login} />} />
        <Route path="/panel/*" element={<Layout />}>
          <Route
            path="dashboard"
            element={<ProtectedRoute component={Dashboard} />}
          />
          <Route
            path="scenario"
            element={<ProtectedRoute component={Scenario} />}
          />
          <Route
            path="createscenario"
            element={<ProtectedRoute component={CreateScenario} />}
          />
          <Route
            path="banners"
            element={<ProtectedRoute component={Banners} />}
          />
          <Route path="users" element={<ProtectedRoute component={Users} />} />
          <Route
            path="createuser"
            element={<ProtectedRoute component={CreateUser} />}
          />
          <Route
            path="edituser/:id"
            element={<ProtectedRoute component={CreateUser} />}
          />
          <Route path="games" element={<ProtectedRoute component={Games} />} />
          <Route
            path="editscenario/:id"
            element={<ProtectedRoute component={CreateScenario} />}
          />
          <Route
            path="creategame"
            element={<ProtectedRoute component={CreateGame} />}
          />
          <Route
            path="editgame/:id"
            element={<ProtectedRoute component={CreateGame} />}
          />
          <Route
            path="selectscenario"
            element={<ProtectedRoute component={SelectScenario} />}
          />
          <Route
            path="gamedetails/:id"
            element={<ProtectedRoute component={GameDetail} />}
          />
          <Route
            path="addbanner"
            element={<ProtectedRoute component={CreateBanner} />}
          />
          <Route
            path="editbanner/:id"
            element={<ProtectedRoute component={CreateBanner} />}
          />
          <Route
            path="characters/:membershipId"
            element={<ProtectedRoute component={Characters} />}
          />
          <Route
            path="avatar/:characterId"
            element={<ProtectedRoute component={Avatar} />}
          />
          <Route
            path=":type/character/"
            element={<ProtectedRoute component={RegisterCharacter} />}
          />
          <Route
            path=":type/character/:id"
            element={<ProtectedRoute component={RegisterCharacter} />}
          />
          <Route
            path="changepassword/:id"
            element={<ProtectedRoute component={ChangePassword} />}
          />
          <Route
            path="sidekick-users"
            element={<ProtectedRoute component={SideKickUser} />}
          />
          <Route
            path="sidekick-statistics"
            element={<ProtectedRoute component={Statistics} />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
