import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import http from "../../utils/http";

const initialState = {
  isLoading: false,
  users: [],
};

export const getSidekickUsers = createAsyncThunk(
  "application/getSidekickUsers",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(
        `${
          process.env.REACT_APP_API_SIDEKICK_BASE_URL
        }/get-all-sidekick-users?limit=${50}&page=${payload?.currentPage}&searchVal=${payload?.debouncedSearchTerm}`
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addTicketsCount = createAsyncThunk(
  "application/addTicketsCount",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_API_SIDEKICK_BASE_URL}/update-user-tickets`,
        payload
      );
      return thunkAPI.fulfillWithValue(response?.data)
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const sidekickUserSlice = createSlice({
  name: "sidekick-users",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSidekickUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSidekickUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(getSidekickUsers.rejected, (state) => {
        state.isLoading = false;
        state.users = [];
      })
      .addCase(addTicketsCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addTicketsCount.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addTicketsCount.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

export default sidekickUserSlice.reducer;
