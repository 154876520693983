import React from "react";

const CardWrapper = ({ children }) => {
  return (
    <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/3 px-3 mb-7">
      {children}
    </div>
  );
};

const CardContent = ({ icon: Icon, title, value }) => {
  return (
    <div className="bg-[#211849] text-white p-8 rounded">
      <div className="flex justify-between items-center">
        <div className="w-[60px] h-[60px] flex justify-center items-center rounded-full bg-white-500 bg-custom-color bg-opacity-100 min-w-[60px]">
          <Icon className="text-white text-[30px]" />
        </div>
        <p className="text-[19px]">{title}</p>
      </div>
      <p className="text-right text-[45px] font-bold">{value}</p>
    </div>
  );
};

export default function Statistics() {
  return (
    <>
      <h2>stisjssmsm</h2>
    </>
  );
}
