import React from "react";
import { getSidekickUsers } from "../../../redux/slices/sidekickuserSlice";
import TicketsPopup from "./TicketsPopup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { LineWave } from "react-loader-spinner";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

export default function SideKickUser() {
  const dispatch = useDispatch();
  const { users, isLoading } = useSelector(
    (state) => state.sidekickuserReducer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketPopup, setTicketPopup] = useState(false);
  const [userData, setUserData] = useState({
    userId: "",
    tickets: ""
  });
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(""); // State for debounced search term
  const [debounceTimeout, setDebounceTimeout] = useState(null); // Timeout for debouncing

  const handlePagination = (e) => {
    setCurrentPage(e.selected + 1);
  };
  console.log({ debouncedSearchTerm })

  const fetchUsers = async function () {
    const response = await dispatch(getSidekickUsers({ currentPage, debouncedSearchTerm })); // Pass debounced searchTerm
    if (response?.payload?.code !== 200) {
      toast.error("Record not found");
    }
  };

  // Handle search input change and debounce
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Clear previous timeout if any
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set new timeout to update the debounced search term
    const newTimeout = setTimeout(() => {
      setDebouncedSearchTerm(value); // Update the debounced value
    }, 2000); // 500ms delay for debouncing

    setDebounceTimeout(newTimeout);
  };

  useEffect(() => {
    fetchUsers(); // Fetch users whenever the currentPage or debouncedSearchTerm changes
  }, [currentPage, debouncedSearchTerm]);

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <LineWave color={"#fff"} loading={isLoading} size={150} />
        </div>
      ) : (
        <div>
          {/* Search input field */}
          <div className="mb-4 flex justify-end">
            <input
              type="text"
              placeholder="Search by Name or Email"
              onChange={handleSearchChange}
              value={searchTerm}
              className="bg-[#372F5B] h-10 px-4 py-2 pl-[35px] text-white rounded-full text-sm focus:outline-none max-w-[300px]"
            />
          </div>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-white dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Membership Id
                  </th>
                  <th scope="col" className="px-6 py-3">
                    No. of Tickets
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tickets
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isLoading && users?.data?.length === 0 ? (
                  <tr>
                    <td
                      colSpan="6"
                      className="px-6 py-4 text-center text-2xl text-gray-500"
                    >
                      No Users Found
                    </td>
                  </tr>
                ) : (
                  users?.data?.map((user) => (
                    <tr
                      className="bg-[#211849] border-b dark:bg-[#211849] dark:border-gray-700"
                      key={user?._id}
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-white whitespace-normal break-words max-w-[400px] dark:text-white"
                      >
                        {user?.name}
                      </th>
                      <td className="px-6 py-4 max-w-[400px] whitespace-normal break-words">
                        {user?.email}
                      </td>
                      <td className="px-6 py-4">{user?.membershipId}</td>
                      <td className="px-6 py-4">{user?.tickets}</td>
                      <td className="px-6 py-4">
                        <button
                          className="bg-green-600 text-white py-1 px-2 text-[12px] rounded-lg w-full max-w-[75px] m-auto"
                          type="button"
                          onClick={() => {
                            setTicketPopup(true);
                            setUserData((prev) => ({
                              ...prev,
                              tickets: user?.tickets,
                              userId: user?.userId
                            }));
                          }}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {users?.data?.length !== 0 && (
            <div className="flex justify-end py-8">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                onPageChange={handlePagination}
                breakLabel="..."
                breakClassName="mx-2 text-white"
                pageCount={users?.meta?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName="flex justify-center"
                previousClassName="px-4 py-2 bg-red-600 text-white rounded-l cursor-pointer"
                nextClassName="px-4 py-2 bg-red-600 text-white rounded-r cursor-pointer"
                activeClassName="px-4 py-2 bg-red-500 text-white"
                pageClassName="px-4 py-2 bg-[#372F5B] text-white"
                forcePage={currentPage - 1}
              />
            </div>
          )}
        </div>
      )}

      {ticketPopup && (
        <TicketsPopup
          setTicketPopup={setTicketPopup}
          userId={userData?.userId}
          tickets={userData?.tickets}
          fetchUsers={fetchUsers}
        />
      )}
    </>
  );
}
