import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import { addTicketsCount, getSidekickUsers } from "../../../redux/slices/sidekickuserSlice";

export default function TicketsPopup({ setTicketPopup, userId, tickets, fetchUsers }) {
  const [ticketCount, setTicketCount] = useState("");
  const dispatch = useDispatch();

  const handleAddTickets = async (e) => {
    e.preventDefault();
    const response = await dispatch(
      addTicketsCount({
        userId,
        ticketCount,
      })
    );
    if (response?.payload?.code === 200) {
      setTicketPopup(false);
      await fetchUsers();
      toast.success("Tickets Added Sucessfully");
    } else {
      toast.error("Something Went Wrong");
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 backdrop-blur-sm  bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-[#150c3f] p-6 rounded-lg shadow-lg text-center w-full max-w-[460px]">
        <button
          className="absolute top-0 sm:right-[-20px] right-0 text-white rounded focus:outline-none text-lg"
          type="button"
          onClick={() => setTicketPopup(false)}
        >
          <RxCross1 />
        </button>
        <div>
          <h1 className="text-white text-2xl mb-3">Add Tickets</h1>
          <form onSubmit={handleAddTickets}>
            <div className="mb-3">
              <label className="block text-sm text-left font-medium text-white">Tickets</label>
              <input
                inputMode="numeric"
                pattern="[0-9]*"
                title="please enter only numeric value"
                className="mt-1 block w-full text-white px-3 py-2 bg-[#372F5B] rounded-md shadow-sm focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50 h-10"
                value={ticketCount}
                onChange={(e) => setTicketCount(e.target.value)}
              />
              <div className=" text-white text-left text-sm mt-2">
                No of Tickets: {tickets}
              </div>
            </div>
            <button
              type="submit"
              className="bg-red-600 py-2 px-3 text-sm text-white w-full rounded-lg mt-2"
            >
              Add
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
